import {createRouter,createWebHashHistory} from "vue-router";
// import * as path from "path";
const routes=[
    // { path:"",
    //     name:"App" ,
    //     component: () => import('./App.vue')
    // },
    // {
    //     path: '/',
    //     name: 'App',
    //     component: () => import('./App.vue'),
    //   },
    {
        path: '/login',
        name: 'Login_page',
        component: () => import('@/views/auth/Login.vue')
    },
    //  print
    {
        path: '/raw_material_form',
        name: 'RawMaterialForm',
        component: () => import('./views/form/RawMaterialForm'),
    } ,
    {
        path: '/raw_material_form/:id/:type',
        name: 'ShowRawMaterialForm',
        component: () => import('./views/form/ShowRawMaterialForm'),
    } ,

    {
        path:'/injection_form/:type/:id/:q_type',
        name:'InjectionForm',
        component:()=>import('./views/form/InjectionForm'),

    },
    {
        path:'/gasket_injection_form:/id',
        name:'GasketInjectionForm',
        component:()=>import('./views/form/GasketInjectionForm'),

    },
    {
        path:'/plunger_injection_form',
        name:'PlungerInjectionForm',
        component:()=>import('./views/form/PlungerInjectionForm'),

    },
    {
        path:'/barrel_injection_form',
        name:'BarrelInjectionForm',
        component:()=>import('./views/form/BarrelInjectionForm'),

    },
    
    {
        path:'/print_barrel_form/:type/:id/:q_type',
        name:'PrintBarrelForm',
        component:()=>import('./views/form/PrintBarrelForm'),

    },
    {
        path:'/silicon_Form/:type/:id/:q_type',
        name:'SilControlForm',
        component:()=>import('./views/form/SilControlForm'),

    },
    {
        path:'/barrel_print_control',
        name:'BarrelPrintControl',
        component:()=>import('./views/form/BarrelPrintControl'),

    },
    
    {
        path:'/non_compliance_form',
        name:'NonComplianceForm',
        component:()=>import('./views/form/NonComplianceForm'),

    },
    {
        path:'/sales_invoice_form/:id/:type/:detail',
        name:'PrintSalesInvoice',
        component:()=>import('./views/order/PrintSalesInvoice'),
    },
    { path:"/",
        name:"Dashboard_page" ,
        component: () => import('@/layouts/Dashboard'),
        children:[
            {
                path:'/tracing_by_UID',
                name:'TracingByUID',
                component:()=>import('./views/packing/TracingByUID'),
            },
            {
                path:'/sales_invoice/:type',
                name:'SalesInvoice',
                component:()=>import('./views/order/SalesInvoice'),
            },
            {
                path:'/list_orders',
                name:'ListOrders',
                component:()=>import('./views/order/ListOrders'),
            },
            {
                path:'/edit_sales_invoice/:id/:type',
                name:'EditSalesInvoice',
                component:()=>import('./views/order/EditSalesInvoice'),
            },
            {
                path:'/',
                name:'DashboardChart',
                component:()=>import('./views/dashboard/DashboardChart'),

            },
            //  users
            {
                path:'/users',
                name:'ManageUser',
                component:()=>import('./views/users/ManageUser'),
    
            },
            //  roles
            {
                path:'/roles',
                name:'ManageRole',
                component:()=>import('./views/roles/ManageRole'),
    
            },
            //  user_type
            {
                path:'/user-type',
                name:'UserType',
                component:()=>import('./views/users/UserType'),
    
            },
            //  depo
            {
                path:'/products',
                name:'ManageProduct',   
                component:()=>import('./views/products/ManageProduct'),
    
            },
            // FinalProduct
            {
                path:'/final_product',
                name:'FinalProduct',   
                component:()=>import('./views/products/FinalProduct'),
    
            },

            // shift
            {
                path:'/shift',
                name:'ShiftComponent',   
                component:()=>import('./views/shift/Shift'),
    
            },

            // production 
            {
                path:'/production-formula',
                name:'ProductionFormula',   
                component:()=>import('./views/production/ProductionFormula'),
    
            },

            {
                path:'/production',
                name:'ProductionForm',         
                component:()=>import('./views/production/Production'),
    
            },

            //  PackingType
            {  
                path:'/packing_type',
                name:'PackingType',
                component:()=>import('./views/packing/PackingType'),
    
            },
            //  SecondaryPacking
            {  
                path:'/secondary_packing',
                name:'SecondaryPacking',
                component:()=>import('./views/packing/SecondaryPacking'),
    
            },
            //  PrimaryPacking
            {  
                path:'/Primary_packing',
                name:'PrimaryPacking',
                component:()=>import('./views/packing/PrimaryPacking'),
    
            },
            {
                path: '/packing_control',
                name: 'PackingControl',
                component: () => import('./views/packing/PackingControl'),
            } ,
            //  Instructions
            {
                path:'/manage_instructions',   
                name:'ManageInstructions',
                component:()=>import('./views/instruction/ManageInstructions'),         
    
            },
            {
                path:'/manage_calibration',
                name:'ManageCalibration',
                component:()=>import('./views/calibration/ManageCalibration'),

            },
            //  DegazinComponent
            {
                path:'/degazin',   
                name:'DegazinComponent',
                component:()=>import('./views/degazin/Degazin'),         
    
            },
            {
                path:'/test_Results_Form',
                name:'TestResultsForm',
                component:()=>import('./views/degazin/TestResultsForm'),

            },
            {
                path:'/identification_tracking_form',
                name:'IdentificationTrackingForm',
                component:()=>import('./views/degazin/IdentificationTrackingForm'),

            },
            {
                path:'/instructions_list',   
                name:'InstructionList',
                component:()=>import('./views/instruction/InstructionList'),         
    
            },   
            // Machine
            {
                path:'/machine',
                name:'ManageMachine',
                component:()=>import('./views/machine/ManageMachine'),
    
            },
            {
                path:'/machine-type',
                name:'ManageMachineType',
                component:()=>import('./views/machine/ManageMachineType'),
    
            },
            //config
            {
                path:'/config',
                name:'Config',
                component:()=>import('./views/config/Config'),
    
            },
            //report
            {
                path:'/production_report',
                name:'ProductionReport',
                component:()=>import('./views/report/ProductionReport'),
    
            },
            // {
            //     path:'/files',
            //     name:'Files',
            //     component:()=>import('./views/config/Files'),
    
            // },

            // Customer
            {
                path:'/customers',
                name:'ManageCustomer',
                component:()=>import('./views/customers/ManageCustomer'),
    
            },
            //supplier
            {
                path:'/supplier',
                name:'ManageSupplier',
                component:()=>import('./views/supplier/ManageSupplier'),
    
            },
            //raw material
            {
                path:'/raw-material',
                name:'RawMaterial',
                component:()=>import('./views/raw_material/RawMaterial'),
    
            },
            {
                path:'/entry-raw-material',
                name:'EntryRawMaterial',
                component:()=>import('./views/raw_material/EntryRawMaterial'),
    
            },
            {
                path:'/raw-material-control',
                name:'RawMaterialControl',
                component:()=>import('./views/raw_material/RawMaterialControl'),
    
            },
            //form builder
            // {
            //     path:'/form_maker',
            //     name:'FormMaker',
            //     component:()=>import('./views/form/FormMaker'),
    
            // },

            {
                path:'/manage_form',
                name:'ManageForm',
                component:()=>import('./views/form/ManageForm'),
            },
            {
                path:'/control_tracking_assembly',
                name:'ControlTrackingAssembly',
                component:()=>import('./views/form/ControlTrackingAssembly'),
            },
            
            // {
            //     path:'/raw_material_form',
            //     name:'RawMaterialForm',
            //     component:()=>import('./views/form/RawMaterialForm'),
    
            // },   

            //warehouse
            {
                path:'/warehouse',
                name:'WarehouseComponent',
                component:()=>import('./views/warehouse/Warehouse'),
    
            },
            {
                path:'/issuing_assignment',
                name:'IssuingAssignment',
                component:()=>import('./views/warehouse/IssuingAssignment'),

            },

            //Defaults
            {
                path:'/injection_default',
                name:'InjectionDefault',
                component:()=>import('./views/form/InjectionDefault'),
            },
            {
                path:'/silicon_default',
                name:'SiliconDefault',
                component:()=>import('./views/form/SiliconDefault'),
            },
            {
                path:'/print_default',
                name:'PrintDefault',
                component:()=>import('./views/form/PrintDefault'),
            },
            {
                path:'/result_Form_default',
                name:'ResultFormDefault',
                component:()=>import('./views/form/ResultFormDefault'),
            },
            {
                path:'/result_form/:type/:id/:q_type',
                name:'ResultForm',
                component:()=>import('./views/form/ResultForm'),
            },
            {
                path:'/identification_form/:q_type/:id',
                name:'IdentificationForm',
                component:()=>import('./views/form/IdentificationForm'),
            },
            {
                path:'/packing_control_form/:q_type/:id',
                name:'PackingControlForm',
                component:()=>import('./views/form/PackingControlForm'),
            },
            {
                path:'/assembly_tracking_process_form/:q_type/:id',
                name:'AssemblyTrackingProcessForm',
                component:()=>import('./views/form/AssemblyTrackingProcessForm'),
            },
            {
                path:'/control_tracking_barrelPrinting_process_form/:q_type/:id',
                name:'ControlTrackingBarrelPrintingProcessForm',
                component:()=>import('./views/form/ControlTrackingBarrelPrintingProcessForm'),
            },
            {
                path:'/control_tracking_injection_process_form/:q_type/:id',
                name:'ControlTrackingInjectionProcessForm',
                component:()=>import('./views/form/ControlTrackingInjectionProcessForm'),
            },
        ]

    },
    // { path:"/ss",
    //     name:"ss_page" ,
    //     component: () => import('@/components/ss_ff.vue') 
    // },

]



const router=createRouter({
    history:createWebHashHistory(),
    base: process.env.BASE_URL,
    routes
})
    // router.beforeEach((to, next) => {
    //     if(to.path ==='/'){
    //         console.log('hi home')
    //         router.push('/dashboard')
    //     }
//     const userInfo = JSON.parse(localStorage.getItem('user_info'))
//     const userPermissions = JSON.parse(localStorage.getItem('user_permissions'))
//     // const authenticationRouteNames = ['Login']
//
//     if (userInfo === null && to.meta.auth) { // check for pages need authentication
//         next('/login')
//     }
//         //else if (userInfo !== null && authenticationRouteNames.includes(to.name)) { // check if logged in user requests for authentication pages
//         //   next('/')
//     //}
//     else  if (to.meta.allowedPermissions) { // check routes accessibility based on user permissions
//         if (userPermissions === null) {
//             next('/')
//         } else if (to.meta.allowedPermissions.filter(val => userPermissions.includes(val)).length === 0) {
//             next('/')
//         } else {
//             next()
//         }
//     } else {
//         next()
//     }
// })
//

export default router;