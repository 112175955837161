import { createApp } from 'vue'
import App from './App.vue'

//store(vuex)
import state from './store/state'
import store from './store/store'

//axios
import axios from 'axios'
axios.defaults.baseURL = state.serverBaseUrl + '/api'
axios.defaults.headers.common['Content-Type'] = 'Application/json'
axios.defaults.headers.common.Accept = 'Application/json'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')

//router
import router from './router.js'

//sass
import './assets/sass/index.sass'
import './style.css'

// notif
import Notifications from '@kyvg/vue3-notification'

import VueApexCharts from "vue3-apexcharts"

// VueSelect 
import vSelect from 'vue-select'

//i18n
import { createI18n } from 'vue-i18n'
import fa from './i18n/fa'
import en from './i18n/en'
const i18n = createI18n({
    locale: 'fa', // set the default locale
    messages: {
      fa,
      en
    }
  })

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (!token && to.path !== '/login') {
    next('/login');
  } else {
    next();
  }
});

const app= createApp(App)
app.use (router)
app.use(i18n)
app.use(store)
app.use(Notifications)
app.use(VueApexCharts)
app.component('v-select', vSelect)
app.config.productionTip = false
app.mount('#app')
