<template>
  <router-view/>
  <notifications/>
</template>

<script>
import 'boxicons/css/boxicons.min.css'
import 'vue-select/dist/vue-select.css';
export default {
  name: "App",
  methods: {
    // search(val) {
      
    // }
  },
  created(){
    localStorage.setItem('index_menu', '0')
    localStorage.setItem('index_sub', null)

  }
}
</script>
<style>
body{
  padding: 0 !important;
}

</style>

